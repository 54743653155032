<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div
          class="pt-4 pb-4 pe-4 ps-4"
          @keyup.enter="getFilteredEntiAggregati"
        >
          <div class="row">
            <div class="col-sm-3 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Codice Aggregazione"
                aria-label=""
                :value="cod_affiliazione"
                @input="updateCodAffiliazione"
              />
              <!-- @keydown.enter="getFilteredEntiAggregati()" -->
            </div>
            <div class="col-sm-3 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Denominazione"
                aria-label=""
                :value="denominazione"
                @input="updateDenominazione"
              />
              <!-- @keydown.enter="getFilteredEntiAggregati()" -->
            </div>
            <div class="col-sm-3 filter">
              <select
                class="form-select"
                aria-label=""
                :value="comitato"
                @change="updateComitato($event)"
              >
                <option value="" selected>Comitato</option>
                <option
                  v-for="comitato in comitati"
                  :key="comitato.id"
                  :value="comitato.id"
                >
                  {{ comitato.label }}
                </option>
              </select>
            </div>
            <div class="col-sm-3">
              <select
                class="form-select"
                aria-label=""
                :value="disciplina"
                @change="updateDisciplina($event)"
              >
                <option value="" selected>Disciplina</option>
                <option
                  v-for="disciplina in elenco_discipline"
                  :key="disciplina.id"
                  :value="disciplina.id"
                >
                  {{ disciplina.nome }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-center mt-5">
            <span
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFilterSociety"
              aria-expanded="false"
              aria-controls="collapseExample"
              class="badge rounded-pill bg-light text-gray-600"
              @click="isActive = !isActive"
              >Filtri avanzati
              <i
                :class="{
                  'fas fa-angle-up fs-6 text-gray-600': isActive,
                  'fas fa-angle-down fs-6 text-gray-600': !isActive,
                }"
              >
              </i
            ></span>
            <button
              v-if="!isActive"
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click.prevent="getFilteredEntiAggregati"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              v-if="!isActive"
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click.prevent="resetFilters"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>

          <div class="collapse" id="collapseFilterSociety">
            <div class="bg-light rounded-3">
              <div class="row mt-4 pt-4 ps-2 pe-2">
                <div class="col-sm-4 filter">
                  <SelectInput
                    :options="affiliazioni_stato"
                    name="statoSocLookup"
                    placeholder="Stato"
                    :value="stato"
                    @changeSelect="updateStato($event)"
                  />
                </div>
                <div class="col-sm-4 filter">
                  <div>
                    <Datepicker
                      v-model="startDateAff"
                      placeholder="Inizio Aggregazione"
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      @update:modelValue="updateInizioAff($event)"
                      autoApply
                    />
                  </div>
                </div>
                <div class="col-sm-4 filter">
                  <div>
                    <Datepicker
                      v-model="endDateAff"
                      placeholder="Fine Aggregazione"
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      @update:modelValue="updateFineAff($event)"
                      autoApply
                    />
                  </div>
                </div>
              </div>
              <div class="row pb-4 ps-2 pe-2">
                <div class="col-sm-4">
                  <div class="pt-4">
                    <select
                      class="form-select"
                      aria-label=""
                      :value="regioneSelected ? regioneSelected : 0"
                      @change="
                        resetIdProvince();
                        filterProvince($event.target.selectedOptions[0]._value);
                        changeIsRegioneSelected($event.target.value);
                      "
                    >
                      <option :value="0">Regione</option>
                      <option
                        v-for="regione in istat_regioni_province"
                        :key="regione.codR"
                        :value="regione.codR"
                        :id="regione.codR"
                        :province="regione.Province"
                      >
                        {{ regione.nomeR }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="pt-4">
                    <select
                      class="form-select"
                      aria-label=""
                      :value="provinciaSelected ? provinciaSelected : 0"
                      @change="
                        setProvinciaSoc($event);
                        changeIsProvinciaSelected($event.target.value);
                      "
                      :disabled="!regioneSelected"
                    >
                      <option :value="0">Provincia</option>
                      <option
                        v-for="provincia in province"
                        :key="provincia.codP"
                        :value="provincia.codP"
                      >
                        {{ provincia.nomeP }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="pt-4">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Comune"
                      aria-label=""
                      @input="
                        spliceComuniIstat();
                        setComuni($event.target.value);
                      "
                      :value="comuneText"
                      @keyup.delete="spliceComuniIstat"
                      :disabled="!provinciaSelected"
                      autocomplete="off"
                    />
                    <ListComuni
                      v-if="comuniIstat"
                      :list="comuniIstat"
                      @hideList="spliceComuniIstat"
                      @selectedElement="
                        comuneString = $event.name;
                        setComuneSoc($event);
                        spliceComuniIstat();
                      "
                    ></ListComuni>
                  </div>
                </div>
              </div>
              <!--   <div class="row pb-4 ps-2 pe-2">
              <div class="col-sm-4">
                <SelectInput
                  :options="trueFalseLookup"
                  name="trueFalseLookup"
                  placeholder="Documenti modifcabili"
                  :value="documentiModificabili"
                  @changeSelect="updateDocumentiModificabili($event)"
                />
              </div>
            </div> -->
            </div>

            <div class="text-center mt-4">
              <button
                v-if="isActive"
                type="button"
                data-bs-toggle=""
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
                class="badge rounded-pill bg-light text-gray-600 ms-4"
                @click.prevent="getFilteredEntiAggregati"
                :disabled="!loaded"
              >
                Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
              </button>
              <button
                v-if="isActive"
                type="button"
                data-bs-toggle=""
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
                class="badge rounded-pill bg-light text-gray-600 ms-4"
                @click.prevent="resetFilters"
              >
                <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// N.B: pagina con metodi obsoleti ma funzionanti
const debugPrint = false;
import {
  ref,
  computed,
  onBeforeMount,
  getCurrentInstance,
  onMounted,
} from "vue";
import Datepicker from "vue3-date-time-picker";
import ListComuni from "../utility/ListComuni.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { useFormatDateYMD, format } from "@/composables/formatDate";

import { useStore } from "vuex";

import "vue3-date-time-picker/dist/main.css";

export default {
  name: "FiltriEntiAggregati",
  components: {
    Datepicker,
    ListComuni,
    SelectInput,
  },
  emits: ["getEntiAggregati", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    //primi eventi
    onBeforeMount(async () => {
      const store = useStore();
      const elenco_discipline = computed(() =>
        store.getters.getStateFromName("elenco_discipline")
      );
      const comitati = computed(() =>
        store.getters.getStateFromName("comitati")
      );
      const istat_regioni_province = computed(() =>
        store.getters.getStateFromName("istat_regioni_province")
      );
      const affiliazioni_stato = computed(() =>
        store.getters.getStateFromName("affiliazioni_stato")
      );
      const keys = ref("c|ddc|irp|afs");
      if (
        !elenco_discipline.value ||
        !comitati.value ||
        !istat_regioni_province.value ||
        !affiliazioni_stato.value
      ) {
        await store.dispatch("setStoreData", {
          keys: { keys: keys.value },
          apiLink: globalApi.COMBOLIST_GET,
        });
      }
    });

    //inizializzazioni
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const comuneText = computed(() => store.getters.comuneTextEntiAggregati);
    const comuneString = ref("");
    const startDateAff = computed(
      () => store.getters.affiliazioneDalEntiAggregati
    );
    const endDateAff = computed(
      () => store.getters.affiliazioneAlEntiAggregati
    );

    // selected regione province
    const isRegioneSelected = ref(false);
    const isProvinciaSelected = ref(false);

    let isActive = ref(false);

    //  store GETTERS
    const id_provincia = computed(() => store.getters.provinciaEntiAggregati);

    // chiamate - store ACTIONS
    const setComuni = (text) => {
      store.commit("setSelectedComuneTextEntiAggregati", text);
      if (text.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: text,
            codp: id_provincia.value,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };

    const resetFilters = () => {
      store.commit("resetEntiAggregati");
      emit("getEntiAggregati");
      startDateAff.value = ref(null);
      endDateAff.value = ref(null);
      isRegioneSelected.value = false;
      isProvinciaSelected.value = false;
      comuneString.value = "";
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedenti_aggregati")
    );
    const getFilteredEntiAggregati = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipEntiAggregati");
      emit("getEntiAggregati");
    };

    // FUNZIONI esterne - store MUTATIONS
    const updateCodAffiliazione = (e) => {
      store.commit("setCodAffiliazioneEntiAggregati", e.target.value);
    };

    const updateDenominazione = (e) => {
      store.commit("setDenominazioneEntiAggregati", e.target.value);
    };

    const updateComitato = (e) => {
      if (e.target.value === "-") {
        store.commit("setComitatoEntiAggregati", null);
        return;
      }
      store.commit("setComitatoEntiAggregati", e.target.value);
    };

    const updateDisciplina = (e) => {
      if (e.target.value === "-") {
        store.commit("setDisciplinaEntiAggregati", null);
        return;
      }
      store.commit("setDisciplinaEntiAggregati", e.target.value);
    };

    const updateInizioAff = (value) => {
      store.commit("setInizioAffEntiAggregati", value);
    };
    const updateFineAff = (value) => {
      store.commit("setFineAffEntiAggregati", value);
    };

    const updateDocumentiModificabili = (value) => {
      store.commit("setDocumentiModificabiliEntiAggregati", value);
    };

    const updateStato = (value) => {
      store.commit("setStatoEntiAggregati", value);
    };

    const istat_regioni_province = computed(() =>
      store.getters.getStateFromName("istat_regioni_province")
    );

    const filterProvince = (id) => {
      // store.commit("setRegioneSoc", 0);
      const regioneSelected = id;
      const provinceSelected = ref(null);
      if (!istat_regioni_province.value) return;
      istat_regioni_province.value.forEach((regione) => {
        if (regione.codR === regioneSelected) {
          provinceSelected.value = regione.Province;
        }
      });
      store.commit("assignStateValue", {
        value: provinceSelected.value,
        stateName: "province",
      });

      store.commit("setRegioneSocEntiAggregati", parseInt(id));
    };
    const setProvinciaSoc = (e) => {
      store.commit("setProvinciaSocEntiAggregati", parseInt(e.target.value));
    };

    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };
    const setComuneSoc = ({ name, value }) => {
      store.commit("setComuneSocEntiAggregati", parseInt(value));
      store.commit("setSelectedComuneTextEntiAggregati", name);
    };

    const changeIsRegioneSelected = (e) => {
      comuneString.value = "";
      if (
        e !== "Regione" &&
        !isNaN(e) &&
        e != null &&
        e != undefined &&
        e != 0
      ) {
        isRegioneSelected.value = true;
      } else {
        isRegioneSelected.value = false;
      }
      isProvinciaSelected.value = false;
    };

    const changeIsProvinciaSelected = (e) => {
      comuneString.value = "";
      if (
        e !== "Provincia" &&
        !isNaN(e) &&
        e != null &&
        e != undefined &&
        e != 0
      ) {
        isProvinciaSelected.value = true;
      } else {
        isProvinciaSelected.value = false;
      }
    };

    spliceComuniIstat();

    const regioneSelected = computed(() => store.getters.regioneEntiAggregati);
    const provinciaSelected = computed(
      () => store.getters.provinciaEntiAggregati
    );
    filterProvince(regioneSelected.value);
    changeIsRegioneSelected(regioneSelected.value);
    changeIsProvinciaSelected(provinciaSelected.value);
    const resetIdProvince = () => {
      store.commit("setProvinciaSocEntiAggregati", null);
      store.commit("setSelectedComuneTextEntiAggregati", "");
    };
    const stato = computed(() => store.getters.statoEntiAggregati);

    onMounted(() => {
      if (
        stato.value ||
        startDateAff.value ||
        startDateAff.value ||
        regioneSelected.value ||
        provinciaSelected.value ||
        comuneText.value
      ) {
        isActive.value = true;
        document.getElementById("collapseFilterSociety").classList.add("show");
      }
    });

    // page bindings
    return {
      // campi e variabili pagina
      isActive,
      isRegioneSelected,
      isProvinciaSelected,
      id_provincia,
      startDateAff,
      endDateAff,
      comuneString,
      // funzionalità
      useFormatDateYMD,
      format,
      changeIsProvinciaSelected,
      changeIsRegioneSelected,
      resetFilters,
      getFilteredEntiAggregati,
      updateCodAffiliazione,
      updateDenominazione,
      updateComitato,
      updateDisciplina,
      updateInizioAff,
      updateFineAff,
      updateDocumentiModificabili,
      updateStato,
      filterProvince,
      setProvinciaSoc,
      setComuni,
      spliceComuniIstat,
      setComuneSoc,
      trueFalseLookup: [
        { id: true, label: "Si" },
        { id: false, label: "No" },
      ],
      // liste old style
      stato,
      documentiModificabili: computed(
        () => store.getters.documentiModificabiliEntiAggregati
      ),
      cod_affiliazione: computed(
        () => store.getters.cod_affiliazioneEntiAggregati
      ),
      denominazione: computed(() => store.getters.denominazioneEntiAggregati),
      disciplina: computed(() => store.getters.disciplinaEntiAggregati),
      regioneSelected,
      provinciaSelected,
      comune: computed(() => store.getters.comuneEntiAggregati),
      comitato: computed(() => store.getters.comitatoEntiAggregati),
      elenco_discipline: computed(() =>
        store.getters.getStateFromName("elenco_discipline")
      ),
      affiliazioni_stato: computed(() =>
        store.getters.getStateFromName("affiliazioni_stato")
      ),
      comitati: computed(() => store.getters.getStateFromName("comitati")),
      istat_regioni_province,
      province: computed(() => store.getters.getStateFromName("province")),
      comuniIstat: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      loaded,
      comuneText,
      resetIdProvince,
    };
  },
};
</script>

<style></style>
