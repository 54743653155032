<template>
  <div>
    <FiltriEntiAggregati
      @getEntiAggregati="searchEntiAggregati"
      @resetFilters="resetFilters"
    />
    <TableEntiAggregati
      @getEntiAggregati="getEntiAggregati"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TableEntiAggregati from "@/components/components-fit/enti-aggregati/TableEntiAggregati.vue";
import FiltriEntiAggregati from "@/components/components-fit/enti-aggregati/FiltriEntiAggregati.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "society",
  components: {
    TableEntiAggregati,
    FiltriEntiAggregati,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Enti Aggregati", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const cod_affiliazione = computed(
      () => store.getters.cod_affiliazioneEntiAggregati
    );
    const denominazioneEntiAggregati = computed(
      () => store.getters.denominazioneEntiAggregati
    );
    const comitato = computed(() => store.getters.comitatoEntiAggregati);
    const rowsToSkipEntiAggregati = computed(
      () => store.getters.rowsToSkipEntiAggregati
    );
    const fetchRowsEntiAggregati = computed(
      () => store.getters.fetchRowsEntiAggregati
    );
    const sortColumnEntiAggregati = computed(
      () => store.getters.sortColumnEntiAggregati
    );
    const sortOrderEntiAggregati = computed(
      () => store.getters.sortOrderEntiAggregati
    );
    const tutteStagioniEntiAggregati = computed(
      () => store.getters.tutteStagioniEntiAggregati
    );
    const stagioneEntiAggregati = computed(() => store.getters.defaultStagione);
    const stagioneCorrenteEntiAggregati = computed(
      () => store.getters.stagioneCorrenteEntiAggregati
    );
    const disciplina = computed(() => store.getters.disciplinaEntiAggregati);
    const affiliazioneDal = computed(
      () => store.getters.affiliazioneDalEntiAggregati
    );
    const affiliazioneAl = computed(
      () => store.getters.affiliazioneAlEntiAggregati
    );
    const regione = computed(() => store.getters.regioneEntiAggregati);
    const provincia = computed(() => store.getters.provinciaEntiAggregati);
    const comune = computed(() => store.getters.comuneEntiAggregati);
    const documentiModificabili = computed(
      () => store.getters.documentiModificabiliEntiAggregati
    );
    const stato = computed(() => store.getters.statoEntiAggregati);

    const getEntiAggregati = () => {
      const localKeys = {
        cod_affiliazione: cod_affiliazione.value,
        denominazione: denominazioneEntiAggregati.value,
        comitato: comitato.value,
        rowsToSkip: rowsToSkipEntiAggregati.value,
        fetchRows: fetchRowsEntiAggregati.value,
        sortColumn: sortColumnEntiAggregati.value,
        sortOrder: sortOrderEntiAggregati.value,
        stagione: tutteStagioniEntiAggregati.value
          ? 10000
          : stagioneEntiAggregati.value,
        stagioneCorrenteEntiAggregati: stagioneCorrenteEntiAggregati.value,
        disciplina: disciplina.value,
        affiliazioneDal: affiliazioneDal.value,
        affiliazioneAl: affiliazioneAl.value,
        regione: regione.value,
        provincia: provincia.value,
        comune: comune.value,
        documentiModificabili: documentiModificabili.value,
        stato: stato.value,
      };
      /*  */
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.ANAGRAFICHE_ENTI_AGGREGATI_LIST,
        itemName: "enti_aggregati",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersEntiAggregati");
      getEntiAggregati();
    };
    getEntiAggregati();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedenti_aggregati")
    );

    const searchEntiAggregati = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipEntiAggregati");
      getEntiAggregati();
    };
    watch(stagioneEntiAggregati, () => searchEntiAggregati());

    return {
      getEntiAggregati,
      resetFilters,
      searchEntiAggregati,
    };
  },
});
</script>
