<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="kt_modal_sgat"
    l-bs-keyboard="false"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Gestione credenziali SGAT</h5>
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="password = null"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body text-start">
          <div class="card-body p-4">
            <Loading
              :active="isLoading"
              :is-full-page="fullPage"
              :z-index="1100"
            />
            <div
              v-if="errorMessage"
              class="row py-5 my-5 text-center justify-content-center text-gray-700 fs-2"
            >
              {{ errorMessage }}
            </div>
            <div v-else class="row px-5 text-center justify-content-center">
              <div class="fw-bold pb-5 mb-2 col-9">{{ societa }}</div>
              <div class="col-9 card shadow">
                <div class="row px-5 text-center justify-content-center">
                  <div class="col-12">
                    <div class="p-4 pb-5">
                      <div class="pt-2 d-flex justify-content-center">
                        <span
                          class="fw-bold text-gray-800 align-self-center pe-5"
                          style="width: 8rem"
                          >Username:</span
                        >
                        <input class="form-control" :value="name" disabled />
                      </div>
                      <div class="pt-2 d-flex">
                        <span
                          class="fw-bold text-gray-800 align-self-center pe-5"
                          style="width: 8rem"
                          >Password:</span
                        >
                        <input
                          class="form-control"
                          v-model="password"
                          type="password"
                          placeholder="********"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-12 text-end pb-5">
                      <button
                        type="submit"
                        class="badge bg-secondary rounded blueFit fs-6"
                        @click="editSgatCredenziali()"
                      >
                        Salva modifiche
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeModalSgat"
            @click="password = null"
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { editSgat } from "@/requests/sgatRequests";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import { ref } from "vue";
import Loading from "vue3-loading-overlay";

export default {
  props: {
    societa: {
      type: String,
    },
    name: {
      type: String,
    },
    id_utente: {
      type: Number,
    },
    errorMessage: {
      type: String,
    },
  },
  components: { Loading },
  setup(props) {
    const isLoading = ref(false);
    const password = ref(null);

    const editSgatCredenziali = () => {
      if (!password.value) {
        alertFailed("Per procedere è necessario inserire una password");
      } else if (password.value.length < 8) {
        alertFailed("La password deve contenere un minimo di 8 caratteri");
      } else {
        isLoading.value = true;
        editSgat(props.id_utente, props.name, password.value).then((res) => {
          isLoading.value = false;
          if (res.status == 200) {
            alertSuccess("Credenziali modificate correttamente");
            document.getElementById("closeModalSgat").click();
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
        });
      }
    };
    return {
      password,
      editSgatCredenziali,
      isLoading,
    };
  },
};
</script>

<style></style>
